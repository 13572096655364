<template>
  <section class="search--content">
    <div class="top--part">
      <h2 class="title--text">
        <span class="bold">
          {{ totalUnit ? totalUnit : '0' }}
        </span>
        {{ seoText }}
      </h2>
      <div class="sort">
        <div
          class="filter--button hide--in-tablet hide--in-desktop"
          @click="openFilterSidebar()"
          v-if="$route.name !== 'search-user-listing' && $route.name !== 'search-agent-catalog'"
        >
          <img src="@/assets/img/icons/filter.svg" />
          <div>Filter</div>
        </div>
        <div class="hide--in-mobile" style="width: 30%" v-else></div>
        <div class="d-flex align-center" style="justify-self: end">
          <div class="sort--text">{{ $t('general.filter.sort') }}</div>
          <v-select
            :value="activeSortBy"
            @input="inputSortByHandler"
            :items="sortBys"
            :item-text="sortByLabel"
            item-value="id"
            class="dropdown basic--dropdown"
            solo
            hide-details
          ></v-select>
        </div>
      </div>
    </div>
    <div class="bottom--part">
      <div class="premier--project-wrapper" v-if="premierProjects && premierProjects.length > 0">
        <div v-for="(project, index) in premierProjects" :key="index" class="project--item">
          <card-project
            :project="project"
            :premier="true"
            :allowMultiImage="true"
            class="card--project-search"
          ></card-project>
        </div>
      </div>
      <div class="card--project-wrapper">
        <div v-for="project in projects" :key="project.uuid" class="project--item">
          <card-project
            :project="project"
            :allowMultiImage="true"
            class="card--project-search"
          ></card-project>
        </div>
        <div v-for="listing in listings" :key="listing.uuid" class="project--item">
          <card-listing
            :listing="listing"
            :allowMultiImage="true"
            class="card--project-search"
            :isSearch="true"
          ></card-listing>
        </div>
      </div>
      <pagination
        v-if="metaProject"
        :push-state="false"
        :meta="metaProject"
        @changePage="onChangePage"
        :maxVisibleButtons="5"
      />
    </div>
  </section>
</template>

<script>
import CardProject from '@/components/utils/card-project';
import CardListing from '@/components/utils/card-listing';
const Pagination = () => import('@/components/utils/fractal-pagination.vue');
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'search-content',
  components: {
    CardProject,
    CardListing,
    Pagination,
  },
  computed: {
    ...mapState({
      metaProject: (state) => state.v2.search.meta,
      projects: (state) => state.v2.search.projects,
      listings: (state) => state.v2.search.listings,
      premierProjects: (state) => state.v2.search.premierProjects,
      selectedPropertyType: (state) => state.v2.search.selectedPropertyType,
      selectedListingType: (state) => state.v2.search.selectedListingType,
      totalUnit: (state) => state.v2.search.totalUnit,
      searchTerm: (state) => state.v2.search.searchTerm,
    }),
    ...mapGetters({
      activeSortBy: 'v2/search/activeSortBy',
      sortBys: 'v2/search/sortBys',
      isSecondary: 'v2/search/isSecondary',
      seoText: 'v2/search/seoText',
    }),
  },
  methods: {
    sortByLabel(value) {
      return this.$i18n.t(`general.filter.${value.name}`);
    },
    inputSortByHandler(value) {
      let sortBy = this.sortBys.find(function (item) {
        return item.id === value;
      });
      this.$store.commit('v2/search/SET_SORT_BY', sortBy);
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
    onChangePage(page) {
      this.$store.commit('v2/search/SET_PAGE', page ? page : 1);
      // DO NOT SET SEED TO NULL WHEN CHANGING PAGE
      // this.$store.commit('v2/search/SET_SEED', null);
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
    openFilterSidebar() {
      this.$store.commit('v2/search/SET_ACTIVE_FILTER_SIDEBAR', true);
    },
  },
};
</script>
